import { type JWT } from "next-auth/jwt";
import { type IUser } from "~/stores/entites";
import { AuthErrors } from "~/types/auth-errors";

export const register = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  recaptchaToken: string,
  locale?: string,
): Promise<JWT> => {
  const config = useRuntimeConfig();
  return await $fetch("/auth/register", {
    baseURL: config.apiBaseUrl,
    headers: {
      "Content-Type": "application/json",
      "x-recaptcha": recaptchaToken,
    },
    method: "POST",
    body: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      locale: locale,
    },
  });
};

export const login = async (
  email: string,
  password: string,
  recaptchaToken: string,
): Promise<JWT> => {
  const config = useRuntimeConfig();
  return await $fetch("/auth/login", {
    baseURL: config.apiBaseUrl,
    headers: {
      "Content-Type": "application/json",
      "x-recaptcha": recaptchaToken,
    },
    method: "POST",
    body: {
      email: email,
      password: password,
    },
  });
};

/**
 * @param refreshToken
 * @returns JwtToken | undefined
 * @description Refreshes the access token
 */
export const refreshTokens = async (
  refreshToken: string,
): Promise<JWT | undefined> => {
  try {
    const config = useRuntimeConfig();
    return await $fetch("/auth/refresh", {
      retry: 0,
      baseURL: config.apiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
      method: "POST",
    }).then((res) => {
      const tokens = res as JWT;
      tokens.expiresAt = tokens.expiresAt ?? 0;
      tokens.refreshExpiresAt = tokens.refreshExpiresAt ?? 0;
      return tokens;
    });
  } catch (error: any) {
    console.error(error.message);
    return {
      accessToken: "",
      refreshToken,
      expiresAt: 0,
      refreshExpiresAt: 0,
      error: AuthErrors.RefreshAccessTokenError,
    };
  }
};

/**
 *
 * @param idToken
 * @returns JwtToken
 */
export const verifyGoogleToken = async (idToken: string): Promise<JWT> => {
  const config = useRuntimeConfig();
  return await $fetch("/auth/oauth/google", {
    baseURL: config.apiBaseUrl,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: { idToken },
  });
};

/**
 *
 *  * @param accessToken
 * @returns IUser | undefined
 *
 */
export const getUser = async (accessToken: string) => {
  try {
    const config = useRuntimeConfig();
    const user = (await $fetch("/users/me", {
      baseURL: config.apiBaseUrl,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      } as HeadersInit,
    })) as IUser | undefined;

    return {
      userId: user?.userId,
      email: user?.email,
      role: user?.role,
      firstName: user?.firstName,
      lastName: user?.lastName,
      image: user?.picture,
      locale: user?.locale,
      createdAt: user?.createdAt ?? new Date(),
      updatedAt: user?.updatedAt ?? new Date(),
    } as IUser;
  } catch (error: any) {
    console.error(error.message);
    return undefined;
  }
};
