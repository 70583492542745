export const useAPIConfig = (recaptchaHeader?: string): APIConfig => {
  const baseURL = "/api/proxy";
  return {
    baseURL,
    initialCache: false,
    retry: 2,
    headers: {
      "Content-Type": "application/json",
      Cookie: useRequestHeaders(["cookie"]).cookie,
      "Accept-Language":
        useCookie("i18n_redirected").value ?? // useLang().currentLocale.value.code didn't work here
        useRequestHeaders(["accept-language"])["accept-language"],
      "x-recaptcha": recaptchaHeader,
    },
  };
};

interface APIConfig {
  baseURL: string;
  initialCache: boolean;
  retry: number;
  headers: {
    "Content-Type": string;
    Cookie: string;
    "Accept-Language": string;
    "x-recaptcha": string;
  };
}
