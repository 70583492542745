import { FetchAPIErrRes } from "../useErrorHandler";
import { IItem } from "~/stores/entites";

export const itemInfo = async (url: string) => {
  return await useFetch("/items/fetch/url-info", {
    ...useAPIConfig(),
    method: "POST",
    retry: 0,
    cache: "no-cache",
    initialCache: false,
    server: false,
    "cache-key": url,
    key: url,
    body: {
      url,
    },
  })
    .then(({ data, error }) => {
      if (error.value) {
        // const err = error.value as FetchAPIErrRes;
        // useErrorHandler(err.data);
        return null;
      } else {
        return data.value as IItem;
      }
    })
    .catch((err) => {
      useErrorHandler(err.data);
    });
};
