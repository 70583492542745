import { FetchAPIErrRes } from "../useErrorHandler";
import type { IItemCoupon, ICouponDealer } from "~/stores/entites";

export const getCoupons = async (
  wishlistId: string,
): Promise<IItemCoupon[] | void> => {
  return await useFetch(`/affiliate/coupons/${wishlistId}`, {
    ...useAPIConfig(),
    method: "GET",
  }).then(({ data, error }) => {
    if (error.value) {
      // const err = error.value as FetchAPIErrRes;
      // useErrorHandler(err.data);
      return undefined;
    } else {
      return data.value as IItemCoupon[];
    }
  });
};
export const getAllCoupons = async (): Promise<ICouponDealer[] | void> => {
  return await useFetch("/affiliate/coupons/all", {
    ...useAPIConfig(),
    method: "GET",
  }).then(({ data, error }) => {
    if (error.value) {
      // const err = error.value as FetchAPIErrRes;
      // useErrorHandler(err.data);
      return undefined;
    } else {
      return data.value as ICouponDealer[];
    }
  });
};
