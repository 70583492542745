import type { FetchAPIErrRes } from "../useErrorHandler";
import type {
  IFavoriteWishlist,
  IIsInFavorites,
  IPagination,
} from "~/stores/entites";

export const getMyFavorites = async (page: number, perPage: number) => {
  return await useFetch(`/favorites/`, {
    ...useAPIConfig(),
    query: {
      page,
      perPage,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      // const err = error.value as FetchAPIErrRes;
      // useErrorHandler(err.data);
      return [];
    } else {
      const wishlists = (data.value as { data: IFavoriteWishlist[] }).data;
      const pagination = data.value as IPagination;
      return { wishlists, pagination };
    }
  });
};

export const isInFavorite = async (
  wishlistId: string,
): Promise<IIsInFavorites | false> => {
  return await useFetch(`/favorites/${wishlistId}`, useAPIConfig()).then(
    ({ data, error }) => {
      // do not throw error if this fails for any reason
      if (error.value) {
        return false;
      } else {
        return data.value as IIsInFavorites;
      }
    },
  );
};

export const createFavorite = async (wishlistId: string) => {
  return await useFetch(`/favorites/${wishlistId}`, {
    ...useAPIConfig(),
    method: "POST",
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return false;
    } else {
      return data.value;
    }
  });
};

export const deleteFavorite = async (wishlistId: string) => {
  return await useFetch(`/favorites/${wishlistId}`, {
    ...useAPIConfig(),
    method: "DELETE",
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return false;
    } else {
      return data.value;
    }
  });
};
