import {
  login,
  register,
  refreshTokens,
  verifyGoogleToken,
  getUser,
} from "./api-services/auth";
import {
  getMyUser,
  updateMyUser,
  deleteMyUser,
  privateMode,
  forgotPassword,
  resetPassword,
} from "./api-services/user";
import {
  getMyWishlists,
  getWishlistById,
  getWishlistByIdForOwner,
  getWishlistBySlug,
  createWishlist,
  updateWishlist,
  deleteWishlist,
} from "./api-services/wishlist";
import { itemInfo } from "./api-services/fetch";
import {
  claimItem,
  verifyClaim,
  cancelClaim,
  fetchClaimsForMyUser,
  fetchClaimsForWishlist,
  claimItemByUser,
} from "./api-services/claim";
import { getCoupons, getAllCoupons } from "./api-services/coupon";
import {
  getMyFavorites,
  isInFavorite,
  createFavorite,
  deleteFavorite,
} from "./api-services/favorite";
import {
  getItemsById,
  getItemsForOwner,
  createItems,
  updateItems,
  deleteItems,
  importItemsFromUrl,
} from "./api-services/item";

export const useAPI = () => {
  return {
    auth: {
      login,
      register,
      refreshTokens,
      verifyGoogleToken,
      getUser,
    },
    user: {
      getMyUser,
      updateMyUser,
      deleteMyUser,
      privateMode,
      forgotPassword,
      resetPassword,
    },
    wishlist: {
      getMyWishlists,
      getWishlistById,
      getWishlistByIdForOwner,
      getWishlistBySlug,
      createWishlist,
      updateWishlist,
      deleteWishlist,
    },
    item: {
      getItemsById,
      getItemsForOwner,
      createItems,
      updateItems,
      deleteItems,
      importItemsFromUrl,
    },
    fetch: {
      itemInfo,
    },
    claim: {
      claimItem,
      claimItemByUser,
      verifyClaim,
      cancelClaim,
      fetchClaimsForMyUser,
      fetchClaimsForWishlist,
    },
    coupon: {
      getCoupons,
      getAllCoupons,
    },
    favorite: {
      getMyFavorites,
      isInFavorite,
      createFavorite,
      deleteFavorite,
    },
  };
};
