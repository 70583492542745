import { capitalize } from "vue";
import { useNotify } from "../stores/notifications";

export interface APIErrorResponse {
  statusCode: number;
  error: string | string[];
  timestamp: Date;
  path: string;
}
export type FetchAPIErrRes = Error & { data: APIErrorResponse };

export const useErrorHandler = (data: APIErrorResponse) => {
  const title: string =
    capitalize(Array.isArray(data.error) ? data.error[0] : data.error) ??
    "notifications.unexpected_error";
  useNotify().show(title, "", false);
};
