import { FetchAPIErrRes } from "../useErrorHandler";
import { IItem, IItemCount } from "../../stores/entites/";

export const getItemsById = async (wishlistId: string) => {
  try {
    return await useFetch(`/items/${wishlistId}`, {
      ...useAPIConfig(),
      method: "GET",
    }).then(({ data, error }) => {
      if (error.value) {
        // const err = error.value as FetchAPIErrRes;
        // useErrorHandler(err.data);
      } else {
        return data.value as IItem[];
      }
    });
  } catch (err) {}
};

export const getItemsForOwner = async (wishlistId: string) => {
  try {
    return await useFetch(`/items/${wishlistId}/owner`, {
      ...useAPIConfig(),
      method: "GET",
    }).then(({ data, error }) => {
      if (error.value) {
        // const err = error.value as FetchAPIErrRes;
        // useErrorHandler(err.data);
      } else {
        return data.value as IItem[];
      }
    });
  } catch (err) {}
};

export const createItems = async (wishlistId: string, items: IItem[]) => {
  await useFetch(`/items/${wishlistId}`, {
    ...useAPIConfig(),
    method: "POST",
    body: {
      items,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value as IItemCount[];
    }
  });
};

export const updateItems = async (wishlistId: string, items: IItem[]) => {
  await useFetch(`/items/${wishlistId}`, {
    ...useAPIConfig(),
    method: "PATCH",
    body: {
      items,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value as IItemCount[];
    }
  });
};

export const deleteItems = async (wishlistId: string, items: string[]) => {
  await useFetch(`/items/${wishlistId}`, {
    ...useAPIConfig(),
    method: "DELETE",
    body: {
      items,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value as IItemCount[];
    }
  });
};

export const importItemsFromUrl = async (
  url: string,
  wishlistId: string,
): Promise<boolean> => {
  await useFetch(`/items/${wishlistId}/import`, {
    ...useAPIConfig(),
    method: "POST",
    body: { url },
  }).then(({ error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return false;
    } else {
      return true;
    }
  });
  return false;
};
