import type { FetchAPIErrRes } from "../useErrorHandler";
import type { IWishlist, PaginatedResponse } from "~/stores/entites";

export const getMyWishlists = async (
  page: number,
  perPage: number,
): Promise<PaginatedResponse<IWishlist> | unknown> => {
  return await useFetch(`/wishlists`, {
    ...useAPIConfig(),
    query: {
      page,
      perPage,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      // const err = error.value as FetchAPIErrRes;
      // useErrorHandler(err.data);
      return [];
    } else {
      return data.value;
    }
  });
};

export const getWishlistById = async (wishlistId: string) => {
  return await useFetch(`/wishlists/${wishlistId}`, useAPIConfig()).then(
    ({ data, error }) => {
      if (error.value) {
        // const err = error.value as FetchAPIErrRes;
        // useErrorHandler(err.data);
        return false;
      } else {
        return data.value;
      }
    },
  );
};

export const getWishlistByIdForOwner = async (wishlistId: string) => {
  return await useFetch(`/wishlists/${wishlistId}/owner`, useAPIConfig()).then(
    ({ data, error }) => {
      if (error.value) {
        // const err = error.value as FetchAPIErrRes;
        // useErrorHandler(err.data);
        return false;
      } else {
        return data.value;
      }
    },
  );
};

export const getWishlistBySlug = async (wishlistSlug: string) => {
  return await useFetch(
    `/wishlists/slug/${wishlistSlug}/`,
    useAPIConfig(),
  ).then(({ data, error }) => {
    if (error.value) {
      // const err = error.value as FetchAPIErrRes;
      // useErrorHandler(err.data);
      return false;
    } else {
      return data.value;
    }
  });
};

export const createWishlist = async (title: string) => {
  return await useFetch(`/wishlists/`, {
    ...useAPIConfig(),
    method: "POST",
    body: { title },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const updateWishlist = async (
  wishlistId: string,
  title: string,
  description: string,
  slug: string,
  published: boolean,
) => {
  return await useFetch(`/wishlists/${wishlistId}`, {
    ...useAPIConfig(),
    method: "PATCH",
    body: {
      title,
      description,
      slug,
      published,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const deleteWishlist = async (wishlistId: string) => {
  return await useFetch(`/wishlists/${wishlistId}`, {
    ...useAPIConfig(),
    method: "DELETE",
  })
    .then(({ error }) => {
      if (error.value) {
        const err = error.value as FetchAPIErrRes;
        useErrorHandler(err.data);
      } else {
        return true;
      }
    })
    .catch((err) => useErrorHandler(err.data));
};
