import type { FetchAPIErrRes } from "../useErrorHandler";
import { type IUser } from "~/stores/entites";

export const getMyUser = async () => {
  const { data, error } = await useFetch("/users/me", useAPIConfig());
  if (error.value) {
    // useErrorHandler((error.value as FetchAPIErrRes).data);
  } else {
    return data.value;
  }
};

export const updateMyUser = async (user: IUser) => {
  return await useFetch("/users/me", {
    ...useAPIConfig(),
    method: "PATCH",
    body: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      locale: user.locale,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const deleteMyUser = async () => {
  return await useFetch("/users/me", {
    ...useAPIConfig(),
    method: "DELETE",
  }).then(({ error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return false;
    }
    return true;
  });
};

export const privateMode = async () => {
  return await useFetch("/wishlists/private-mode/all", {
    ...useAPIConfig(),
    method: "PATCH",
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const forgotPassword = async (
  email: string,
  recaptchaToken: string,
): Promise<boolean> => {
  return await useFetch(`/auth/forgot-password`, {
    ...useAPIConfig(recaptchaToken),
    method: "POST",
    body: {
      email,
    },
  }).then(({ error }) => {
    if (error.value) {
      return false;
    } else {
      return true;
    }
  });
};

export const resetPassword = async (
  password: string,
  recaptchaToken: string,
  token: string,
): Promise<boolean> => {
  return await useFetch(`/auth/reset-password`, {
    ...useAPIConfig(recaptchaToken),
    method: "PATCH",
    body: {
      password,
      token,
    },
  }).then(({ error }) => {
    if (error.value) {
      return false;
    } else {
      return true;
    }
  });
};
