import type { FetchAPIErrRes } from "../useErrorHandler";
import {
  type IUserClaim,
  type IWishlistClaim,
  type PaginatedResponse,
} from "~/stores/entites";

export const claimItem = async (
  itemId: string,
  claimerName: string,
  recaptchaToken: string,
) => {
  return await useFetch(`/claim`, {
    ...useAPIConfig(recaptchaToken),
    method: "POST",
    body: {
      name: claimerName,
      itemId,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const claimItemByUser = async (
  itemId: string,
  claimerName: string,
  recaptchaToken: string,
  claimerEmail?: string,
) => {
  return await useFetch(`/claim/by-user`, {
    ...useAPIConfig(recaptchaToken),
    method: "POST",
    body: {
      email: claimerEmail,
      name: claimerName,
      itemId,
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    } else {
      return data.value;
    }
  });
};

export const verifyClaim = async (claimId: number, hash: string) => {
  return await useFetch(`/claim/verify`, {
    ...useAPIConfig(),
    method: "PATCH",
    body: {
      hash,
      claimId: claimId.toFixed(),
    },
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
    }
    return data.value;
  });
};

export const cancelClaim = async (
  wishlistId: string,
  itemId: string,
): Promise<boolean> => {
  return await useFetch(`/claim/cancel/${wishlistId}/${itemId}`, {
    ...useAPIConfig(),
    method: "PATCH",
  }).then(({ error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return false;
    } else {
      return true;
    }
  });
};

export const fetchClaimsForWishlist = async (
  wishlistId: string,
): Promise<IWishlistClaim[]> => {
  return await useFetch(`/claim/wishlist/${wishlistId}`, {
    ...useAPIConfig(),
    method: "GET",
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return [];
    } else {
      return data.value as IWishlistClaim[];
    }
  });
};

export const fetchClaimsForMyUser = async (
  page: number,
  perPage: number,
): Promise<PaginatedResponse<IUserClaim> | unknown> => {
  return await useFetch(`/claim/user`, {
    ...useAPIConfig(),
    query: {
      page: page,
      perPage: perPage,
    },
    method: "GET",
  }).then(({ data, error }) => {
    if (error.value) {
      const err = error.value as FetchAPIErrRes;
      useErrorHandler(err.data);
      return [];
    } else {
      return data.value;
    }
  });
};
